<template>
  <div>
    <div class="card-columns">
      <div
        class="card text-white text-center p-3"
        v-for="sectorCategory in sectorCategorys"
        :key="sectorCategory.id"
        :class="'bgcolorbox'+sectorCategory.codeno"
      >
        <blockquote class="blockquote mb-0">
          <p>{{sectorCategory.codename}}</p>
          <footer class="blockquote-footer">
            <small>
              
            </small>
          </footer>
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
//import memberListService from "../services/memberListService";
//import loginService from "../services/LoginService";
import codeService from "../services/codeService";
//import $ from 'jquery';

export default {
  name: "list",
  component: {},
  data() {
    return {
      deptCategorys: {},
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      submitted: false,
      deptCategory: "",
      currentDept: "",
      memberLists: [],
      sectorCategorys:[],
      
    };
  },
  created() {
       this.getSectorCategory();
    // console.log("logininfo_farmname==>"+localStorage.getItem('logininfo_farmname'));
    // if (localStorage.getItem("logininfo_farmname") == null) {
    //   this.$router.push("/login");
    // }
  },
  computed: {
    info_farmname() {
      return localStorage.getItem("logininfo_farmname");
    },
    info_mastername() {
      return localStorage.getItem("logininfo_mastername");
    },
    info_farmcode() {
      return localStorage.getItem("logininfo_farmcode");
    },
    info_sector() {
      return localStorage.getItem("logininfo_sector");
    },
  },
  methods: {
    // 감염 발생 경로
    getSectorCategory() {
      const data = {
        grp: "100", //감염발생구분
      };

      codeService
        .getList(data)
        .then((response) => {
          this.sectorCategorys = response.data;
       //   console.log("sectorCategorys ==> " + JSON.stringify(response.data));
        })
        .catch((e) => {
          console.log(e);
        });
    },

    /*
    changeDept() {
     
      var opt = $("#dept option:selected").text();
      console.log(opt);

      this.currentDept = opt;
      const data = {
        dept :  opt
      };
      memberListService
        .getList(data)
        .then((response) => {
          this.memberLists = response.data;
          console.log("deptCategory ==> " +JSON.stringify(response.data));
        })
        .catch((e) => {
          console.log(e);
        });
      },
    trgtRegister(memberList){
      this.$router.push({name: "trgtRegister", params: {memberno: memberList.memberno, membername: memberList.membername, dept: memberList.dept, tel: memberList.tel }});
    },
    updateTutorial(tutorial) {
      //e.preventDefault();
      // let myForm = document.getElementById("checkForm")
      // let form = new FormData(myForm);

      const data = {
        id: tutorial.id,
        words: tutorial.words,
        pray: tutorial.pray,
        opinon: tutorial.opinon,
      };
      // need to convert it before using not with XMLHttpRequest
      // for (let [key, val] of form.entries()) {
      //   Object.assign(data, { [key]: val })
      //  // console.log({ [key]: val })
      // }
      //console.log("tutorial=="+tutorial.words)
      //console.log(...form.entries())
      if (tutorial.words || tutorial.pray) {
        memberListService
          .update(tutorial.id, data)
          .then((response) => {
            console.log(response.data);
            this.message = "";
            this.$toast.show("저장되었습니다.", {
              type: "warning",
              position: "top-right",
            });
          })
          .catch((e) => {
            this.$toast.show("다시 확인해주세요.", {
              type: "success",
              position: "top-right",
            });
            console.log(e);
          });
      } else {
        this.$toast.show("입력 내용이 없습니다.", {
          type: "success",
          position: "top-right",
        });
      }
    },

    retrieveDeptCategory() {
      // const data = {farmcode: this.farmcode}
      loginService
        .getDept()
        .then((response) => {
          this.deptCategorys = response.data;
          console.log("deptCategory ==> " + this.deptCategorys);
        })
        .catch((e) => {
          console.log(e);
        });
    },
*/
  },
  mounted() {
   
  },
};
</script>

<style>
.bgcolorbox10 {
    background: rgb(207, 82, 107);
}
.bgcolorbox11 {
    background: #0062cc;
}
.bgcolorbox12 {
    background: rgb(183, 220, 20);
}
.bgcolorbox13 {
    background: rgb(179, 43, 241);
}
.bgcolorbox14 {
    background: rgb(230, 154, 14);
}
.bgcolorbox15 {
    background: rgb(5, 122, 143);
}
.bgcolorbox16 {
    background: rgb(2, 148, 116);
}
.bgcolorbox17 {
    background: rgb(219, 187, 4);
}
.bgcolorbox18 {
    background: rgb(4, 116, 9);
}
.bgcolorbox19 {
    background: rgb(4, 116, 9);
}
.bgcolorbox20 {
    background: rgb(4, 116, 9);
}



.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
.done {
  background: oldlace;
}
</style>
